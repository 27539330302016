import React from 'react';
import { getData, getVar } from '../../services/WebService';
import { Link } from 'react-router-dom';

class Footer extends React.Component {

    constructor() {
        super();

        this.state = {
            dom: getVar()[0],
            Url: "https://www.paginas.virextec.com/archivos/",
            org: getVar()[1],
            web: getVar()[2],
            webSite: {},
            sections: [],
            redes: [],
            servicios: []
        }
    }

    componentDidMount = () => {

        this.miData();

    }

    miData = async () => {
        const {
            dom, org, web, webSite, sections, redes, servicios
        } = this.state;

        if (sessionStorage.getItem('empresa') == null) {
            console.log('no hay datos.... EN FOOTER');
            return await getData(0, dom, org, web, 0, 0, 0, 0, 0).then(res => {
                sessionStorage.setItem('empresa', JSON.stringify(res.data));

                this.setState({
                    webSite: res.data.web[0],
                    sections: res.data.sections,
                    redes: res.data.redes,
                    servicios: res.data.proAll
                });
            })
        } else {
            console.log('ya hay datos cargados EN FOOTER');
            let data = JSON.parse(sessionStorage.getItem('empresa'));
            this.setState({
                webSite: data.web[0],
                sections: data.sections,
                redes: data.redes,
                servicios: data.proAll
            });
        }
    }

    render() {

        const {
            webSite, sections, redes, servicios, Url
        } = this.state;

        const displayRedes = (redes || []).map((item) => {
            return (
                <li key={item?.RedID}>
                    <a href={`${item?.RedLin}`} rel='noreferrer' target="_blank" aria-label="Facebook"><i className={`fab fa-${item?.RedIco}`}></i></a>
                </li>
            )
        });

        const displayServicios = (servicios || []).map((item) => {

            return (
                <li key={item?.ProID}><Link to={`servicios/${item?.ProID}`}>{item?.ProNom}</Link></li>
            )
        });

        const displaySection = (sections || []).map((item) => {
            return (
                <li key={item?.ConID}><Link to={item?.Url}>{item?.Tit}</Link></li>
            )
        })

        return (
            <>

                <footer id="footer" className="footer bg-overlay">
                    <div className="footer-main">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-lg-4 col-md-6 footer-widget footer-about">
                                    <h3 className="widget-title">Acerca de Nosotros</h3>
                                    <img loading="lazy" className="footer-logo" src="logo-molissa-web-2.png" alt={webSite?.Tit} />
                                    <p>{webSite?.Des}</p>
                                    <div className="footer-social">
                                        <ul>
                                            {displayRedes}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
                                    <h3 className="widget-title">HORAS LABORALES</h3>
                                    <div className="working-hours">
                                        <p>Trabajamos los 7 días de la semana, todos los días excepto los feriados importantes. Comuníquese con nosotros si tiene una emergencia, con nuestra línea directa y formulario de contacto.</p>
                                        <br /> Lunes - Viernes: <span className="text-right">9:00 - 19:00 </span>
                                        <br /> Sabados: <span className="text-right">9:00 - 16:00</span>
                                        <br /> Domingo y fereados: <span className="text-right">9:00 - 12:00</span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
                                    <h3 className="widget-title">Servicios</h3>
                                    <ul className="list-arrow">
                                        {displayServicios}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="copyright-info">
                                        <span>Copyright &copy; <script>
                                        </script>, Designed &amp; Developed by <a href="http://virextec.com/" target="_blank" rel='noreferrer'>VirexTec</a></span>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="footer-menu text-center text-md-right">
                                        <ul className="list-unstyled mb-0">
                                            {displaySection}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div id="back-to-top" data-spy="affix" data-offset-top="10" className="back-to-top position-fixed">
                                <button className="btn btn-primary" title="Back to Top">
                                    <i className="fa fa-angle-double-up"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </footer>

            </>
        )
    }
}

export default Footer;