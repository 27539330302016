import React from 'react';
import { getData, getVar } from '../../services/WebService';
import { Link } from 'react-router-dom';
import Banner from '../genericas/Banner';

class Nosotros extends React.Component {

    constructor() {
        super();

        this.state = {
            dom: getVar()[0],
            Url: "https://www.paginas.virextec.com/archivos/",
            org: getVar()[1],
            web: getVar()[2],
            webSite:{},
            sections:[],
            redes:[],
            servicios:[]
        }
    }

    componentDidMount = () => {
        this.miData();        
    }

    miData = async () =>{
        const{
            dom, org, web, webSite, sections, redes, servicios
        }=this.state;

        if(sessionStorage.getItem('empresa')==null){
            console.log('no hay datos.... EN FOOTER');
            return await getData(0, dom, org, web,0,0,0,0, 0).then(res =>{
                sessionStorage.setItem('empresa', JSON.stringify(res.data));            

                this.setState({
                    webSite: res.data.web[0],
                    sections: res.data.sections,
                    redes: res.data.redes,
                    servicios: res.data.proAll
                });
            })
        }else {
            console.log('ya hay datos cargados EN FOOTER');
            let data = JSON.parse(sessionStorage.getItem('empresa'));
            console.log("mi data es...", data)
            this.setState({
                webSite: data.web[0],
                sections: data.sections,
                redes: data.redes,
                servicios: data.proAll
            });            
        }
    }

    render() {

        const{
            webSite, sections, redes, servicios, Url
        }=this.state;

        return (
            <>
                <Banner />
                <section id="main-container" className="main-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3 className="column-title">Quienes somos</h3>
                                <p>{webSite?.Des}</p>
                                <blockquote><p>Semporibus autem quibusdam et aut officiis debitis aut rerum est aut optio cumque nihil necessitatibus autemn ec tincidunt nunc posuere ut</p></blockquote>
                                <p>He lay on his armour-like  back, and if he lifted. ultrices ultrices sapien, nec tincidunt nunc posuere ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing.</p>

                            </div>

                            <div className="col-lg-6 mt-5 mt-lg-0">

                                <div id="page-slider" className="page-slider small-bg">

                                    <div className="item" style={{backgroundImage: "archivos/images/logo sin fondo.png"}}>
                                        <div className="container">
                                            <div className="box-slider-content">
                                                <div className="box-slider-text">
                                                    <h2 className="box-slide-title">Molissa</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>


                            </div>
                        </div>

                    </div>
                </section>
            </>
        )
    }
}

export default Nosotros;