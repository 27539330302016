import React from 'react';
import { getData, getVar } from '../../services/WebService';
import Banner from '../genericas/Banner';

class Clientes extends React.Component{

    constructor(){
        super();

        this.state={
            dom:getVar()[0],
            Url:"https://www.paginas.virextec.com/archivos/",
            org:getVar()[1],
            web:getVar()[2],
        }
    }

    componentDidMount = () =>{

    }

    render(){
        
        return(
            <>
                <Banner />
            </>
        )
    }
}

export default Clientes;