import axios from 'axios';

const getData = async (acction,dom,org,web,proTip,proMat,proMar,proFam,proBus)=>{
    return await axios.get('https://www.paginas.virextec.com/servidor/MyWebSiteService.php',{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        params: {acction:acction,dom:dom,org:org,web:web,proTip:proTip,proMat:proMat,proMar:proMar,proFam:proFam,proBus:proBus}    
      });
};

function getVar(){
    let data = ['molissa.com.pe', 7, 6];
    return data;
};

export { getData, getVar };