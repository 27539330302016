import React from 'react';
import { getData, getVar } from '../../services/WebService';
import { Link } from 'react-router-dom';

class Menu extends React.Component{

    constructor(){
        super();

        this.state={
            dom:getVar()[0],
            Url:"https://www.paginas.virextec.com/archivos/",
            org:getVar()[1],
            web:getVar()[2],
            webSite:{},
            sections:[],
            redes:[]
        }
    }

    componentDidMount = () =>{
        this.miData();
    }

    miData = async () =>{
        const{
            dom, org, web, webSite, sections, redes
        }=this.state;

        if(sessionStorage.getItem('empresa')==null){
            console.log('no hay datos....');
            return await getData(0, dom, org, web,0,0,0,0, 0).then(res =>{
                sessionStorage.setItem('empresa', JSON.stringify(res.data));
                console.log(res.data)               

                this.setState({
                    webSite: res.data.web[0],
                    sections: res.data.sections,
                    redes: res.data.redes
                });
            })
        }else {
            console.log('ya hay datos cargados');
            let data = JSON.parse(sessionStorage.getItem('empresa'));

            console.log(data)

            this.setState({
                webSite: data.web[0],
                sections: data.sections,
                redes: data.redes
            });
        }
    }


    render(){
        const {
            webSite, sections, Url, redes
        }=this.state;

        const displayRedes =  (redes || []).map((item)=>{
            return(
                <a key={item?.RedID} title={item?.RedNom} href={item?.RedLin}>
                    <span className="social-icon"><i className={`fab fa-${item?.RedIco}`}></i></span>
                </a>
            )
        });

        const displaySection = (sections || []).map((item)=>{
            return(
                <li key={item?.ConID} className="nav-item">
                    <Link to={item?.Url} className="nav-link">{item?.Tit}</Link>
                </li>
            )
        })

        return(
            <>
            <div id="top-bar" className="top-bar">
                <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8">
                        <ul className="top-info text-center text-md-left">
                            <li><i className="fas fa-map-marker-alt"></i> <p className="info-text">{webSite?.WebSitDir1}</p>
                            </li>
                        </ul>
                    </div>
        
                    <div className="col-lg-4 col-md-4 top-social text-center text-md-right">
                        <ul className="list-unstyled">
                            <li>
                                {displayRedes}
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>

            <header id="header" className="header-one">
                <div className="bg-white">
                    <div className="container">
                        <div className="logo-area">
                            <div className="row align-items-center">
                                <div className="logo col-lg-3 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                                    <a className="d-block" href="index.html">
                                        <img loading="lazy" src={`${Url}${webSite?.WebSitLog}`} alt={webSite?.Tit} />
                                    </a>
                                </div>
                                <div className="col-lg-9 header-right">
                                    <ul className="top-info-box">
                                        <li>
                                            <div className="info-box">
                                            <div className="info-box-content">
                                                <p className="info-box-title">Llámanos:</p>
                                                <p className="info-box-subtitle">{webSite?.WebSitNum1}</p>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-box">
                                            <div className="info-box-content">
                                                <p className="info-box-title">Email:</p>
                                                <p className="info-box-subtitle">{webSite?.WebSitEma1}</p>
                                            </div>
                                            </div>
                                        </li>
                                        <li className="header-get-a-quote">
                                            <Link to='contacto' className='btn btn-primary'>Contactanos</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                </div>
                <div className='site-navigation'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <nav className='navbar navbar-expand-lg navbar-dark p-0'>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div id="navbar-collapse" className="collapse navbar-collapse">
                                        <ul className="nav navbar-nav mr-auto">
                                            {displaySection}
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            </>
        )
    }
}

export default Menu;