import React from 'react';
import { getData, getVar } from '../../services/WebService';
import { Link } from 'react-router-dom';

class Banner extends React.Component{

    constructor(){
        super();

        this.state={
            dom:getVar()[0],
            Url:"https://www.paginas.virextec.com/archivos/",
            org:getVar()[1],
            web:getVar()[2],
            website: {},
            sections: []
        }
    }

    componentDidMount = () =>{
        this.miData();
    }

    miData = async () => {

        const{
            dom, org, web, website, sections
        }=this.state;

        if(sessionStorage.getItem('empresa')==null){
            return await getData(0, dom, org, web,0,0,0,0, 0).then(res =>{
                sessionStorage.setItem('empresa', JSON.stringify(res.data));
                console.log(res.data)               

                this.setState({
                    webSite: res.data.web[0],
                    sections: res.data.sections
                });
            })
        }else{
            let data = JSON.parse(sessionStorage.getItem('empresa'));

            console.log(data)

            this.setState({
                webSite: data.web[0],
                sections: data.sections
            });
        }
    }

    render(){
        const{
            webSite, Url, sections
        }=this.state;

        const displayBanner = (sections || []).map((item)=>{
            let link = window.location.pathname;

            if(link == item.Url){    
                return(
                    <div key={item?.ConID}  id="banner-area" className="banner-area" style={{backgroundImage: `url(${Url}${item?.Img})`}}>
                        <div className="banner-text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="banner-heading">
                                            <h1 className="banner-title">{item?.Tit}</h1>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb justify-content-center">
                                                    <li className="breadcrumb-item"><Link to='/'>INICIO</Link></li>
                                                    <li className="breadcrumb-item"><Link to='#'>{item?.Tit}</Link></li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }else return null;
        })

        return (
            <>
            {displayBanner}
            </>
        )
    }
}

export default Banner;